import { Carousal } from "../Common/Carousal"
import "../../scss/publicProfile.scss"
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from "react-toastify"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import { Link } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import "../../scss/profileList.scss"

import {
  resetUpdatedProfile, getAllStars,
  getAllRasis, getAllDistricts, getAllJobs, getAllQualifications,getAllCastes,
  getProfileDetailsById, getProfileImageUrl, removeProfileImage,
  deleteProfile,
  updateProfile,uploadfile,setProfilePicture,resetProfilePicture,
  resetUpdatedProfileMessage,
  resetDeleteProfileMessage,getAllForeignCountries,getAllJobLocations,getAllSettleLocations
} from "../../Features/Slices/profSlice"

export function EditProfile() {

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [hasError, setHasError] = useState(false);
  const [DOBBirth, setDOBDate] = useState(null);

  const profileId = searchParams.get('id')

  const { broker, isError, isSuccess, isLoading, message, isStarListSuccess,
    isStarListLoading, stars, isRasiListLoading, isRasiListSuccess, rasis
    ,isDistrictListLoading,isDistrictListSuccess, districts,religions,qualifications,castes, 
    isQualificationLoading, isQualificationSuccess,iscasteLoading,iscasteSuccess,jobs, isJobListLoading,
     isJobListSuccess, profileDetails, Images,isRemoveProfileImageSuccess, 
     removeProfileImagemessage, isRemoveProfileImageError,isdeleteProfileError,
     isdeleteProfileSuccess,messagedeleteProfile,isUpdatedProfileLoading,isUpdatedProfileSuccess,
     updatedProfilemessage,isUpdatedProfileError,issetProfilePictureError,issetProfilePictureSuccess,issetProfilePictureLoading,
     messagesetProfile,isForeignListSuccess,isForeignListLoading,foreignCountrys,isJobLocationSuccess,isJobLocationLoading,jobLocations,
     isSettleLocationSuccess,isSettleLocationLoading,settleLocations
     } =
    useSelector(
      (state) => state.prof
    )

  const [formData, setFormData] = useState(profileDetails)
  //setDOBDate(profileDetails.DOB)

  const { name, qualification, POB,DOB,maritalstatus, birthTime, phoneNumber, job, salary,
    fatherOccupation, motherOccupation, sex, religion, caste, subcaste,star, district, state, address1,
    address2, rasi, sistersMarried,sistersUnmarried,brothersMarried,brothersUnmarried,notes,
     status, brokerId,fatherName, motherName, colour, height, weight, bloodGroup, jobDescription, jobLocation,foreignCountry,
      settledLocation, dhosam, selfDescription, deleteName } = formData
  
  const onchange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  useEffect(() => {
    if (isError != undefined && isError) {
      toast.error(message)
    }
    if (isSuccess != undefined && isSuccess) {
      navigate('/BrokerList', { replace: true });
    }
    if (!isDistrictListLoading && !isDistrictListSuccess) {
      dispatch(getAllDistricts())
      console.log(districts)
    }
    if(!isForeignListLoading && !isForeignListSuccess){
      dispatch(getAllForeignCountries())
      console.log(foreignCountrys )
    }
    if(!isJobLocationLoading && !isJobLocationSuccess){
      dispatch(getAllJobLocations())
    }
    if(!isSettleLocationLoading && !isSettleLocationSuccess){
      dispatch(getAllSettleLocations())
    }

    if (!isStarListLoading && !isStarListSuccess) {
      dispatch(getAllStars())
    }
    if (!isRasiListLoading && !isRasiListSuccess) {
      dispatch(getAllRasis())
    }

    if (!isJobListLoading && !isJobListSuccess) {
      dispatch(getAllJobs())
    }

    if (!isQualificationLoading && !isQualificationSuccess) {
      dispatch(getAllQualifications())
    }

    if(!iscasteLoading && !iscasteSuccess)
      {
        dispatch(getAllCastes())
      }
    
    dispatch(getProfileDetailsById(profileId))
    console.log('profileDetails')
    console.log(profileDetails)
    
    setDOBDate(profileDetails.DOB)

    dispatch(getProfileImageUrl({ "brokerId": null, "profileId": profileId }))

  }, [broker, isError, isSuccess, isLoading, message, navigate], dispatch)

  useEffect(() => {
    if (isRemoveProfileImageSuccess && removeProfileImagemessage != undefined && removeProfileImagemessage.isSuccess) {
      toast.success(removeProfileImagemessage.message)
      dispatch(getProfileImageUrl({ "brokerId": null, "profileId": profileId }))
    }
    else if (removeProfileImagemessage != undefined && removeProfileImagemessage.isSuccess == false) {
      toast.error(removeProfileImagemessage.message)
    }
    else if (isRemoveProfileImageError == true) {
      toast.error("Network Error!!!")
    }

    if(isdeleteProfileSuccess)
    {
      toast.success(messagedeleteProfile)
      dispatch(resetDeleteProfileMessage())
     // navigate('/ProfileList?id='+ brokerId, { replace: true });
     
     // navigate('/ProfileList?id='+ brokerId, { replace: true });
    }
    else if(isdeleteProfileError)
    {
        toast.error("Network Error!!!")
    }
    else if(isUpdatedProfileSuccess)
    {
      toast.success(updatedProfilemessage)
      dispatch(resetUpdatedProfileMessage())
    }
    else if(isUpdatedProfileError)
    {
      toast.error(updatedProfilemessage)
      dispatch(resetUpdatedProfileMessage())
    }
    else if(issetProfilePictureSuccess)
    {
      toast.success(messagesetProfile)
      dispatch(resetProfilePicture())
    }
    else if(issetProfilePictureError)
    {
      toast.success(messagesetProfile)
      dispatch(resetProfilePicture())
    }
    else if(issetProfilePictureSuccess)
    {
      toast.success(messagesetProfile)
    }

  }, [isRemoveProfileImageSuccess, removeProfileImagemessage,isdeleteProfileError,
    isdeleteProfileSuccess,messagedeleteProfile,isUpdatedProfileLoading,
    isUpdatedProfileSuccess,updatedProfilemessage,isUpdatedProfileError,
    issetProfilePictureSuccess,messagesetProfile]);

  const onSubmit = (e) => {
    e.preventDefault();

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.name
    }))

    const profileData = {
      profileId,name, qualification, DOBBirth, POB,maritalstatus, birthTime, phoneNumber, job, salary,
      fatherOccupation, motherOccupation, sex, religion,star, caste, subcaste, district, state,
      address1, address2, rasi, sistersMarried,sistersUnmarried,brothersMarried,brothersUnmarried,notes, status, brokerId,
      fatherName, motherName, colour, height, weight, bloodGroup, jobDescription, jobLocation,foreignCountry, settledLocation, dhosam, selfDescription
    }

    if (name == '') {
      toast.error('Please fill the name field')
    }
    else if (sex == 'Select') {
      toast.error('Please select the gender')
    }
    else if (qualification == '') {
      toast.error('Please enter your qualification')
    }
    else {
      dispatch(updateProfile(profileData))
    }


  }
  const backViewDetailsUrl ='/PublicProfile?id='+ profileId
  const onCancelClick = () => {
    navigate(backViewDetailsUrl)
  }

  const onResetClick = () => {
    var registerProfileelement = document.getElementById("frmregisterprofile");
    registerProfileelement.reset()
    dispatch(resetUpdatedProfile())
  }

  const onProfileImageDelete = (image) => {
    let imageName = image.split("/")[4].split("?")[0]
    const data = { "brokerId": null, "profileId": profileId, "imageName": imageName }
    dispatch(removeProfileImage(data))
  

  }
  const onsetProfile = (image)=>{
    let updatedImage = []
    Images.map((img) => {
      if(img ==image )
      {
        updatedImage.push({name:getImageNameFromPath(img),isProfile:true})
      }
      else
      {
        updatedImage.push({name:getImageNameFromPath(img),isProfile:false})
      }
    });
    const data = {"profileId": profileId,"images":updatedImage}

    dispatch(setProfilePicture(data))
  }

  const getImageNameFromPath = (imagePath) => {
    const imageNameWithQuery = imagePath.split('/').pop();
    const imageName = imageNameWithQuery.split('?')[0];
    return imageName;
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const onDeleteClick = () => {
    
    if (deleteName != name) {
      setHasError(true)
      handleShow()
    }
    else {
      handleClose()
      const data = {"profileId": profileId }
      dispatch(deleteProfile(data))
    }
  }

  const formatDate=(dateTime)=>{
 
    const date = new Date(dateTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${ day}/${month}/${year}`; // Format as YYYY-MM-DD
  
}



  const onDeleteNamechange = () => {
    if (deleteName != name) {
      setHasError(true)
    }
    else {
      setHasError(false)
    }
  }

  function uploadImage() {
    if(filedata !=undefined)
    dispatch(uploadfile([filedata, "data"]))
   
  }

  const [fileurl, setFileUrl] = useState();
  const [filedata, setFiledata] = useState();


  function handleChange(e) {
    const formData = new FormData()
    const uploadedFile = e.target.files[0];
    const newName = `${Date.now()}`;
    const fileExtension = uploadedFile.name.split('.').pop();
    const modifiedFile = new File([uploadedFile], newName + fileExtension, { type: uploadedFile.type });
    setFileUrl(URL.createObjectURL(modifiedFile));
    formData.append("file", modifiedFile)
    formData.append("profileId", profileId)
    setFiledata(formData)
  }

  
  return (
    <>
      <div>

      <Link to={backViewDetailsUrl} className="dropdown-item d-flex align-items-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#1aa179" className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
</svg><p className="h6 mb-0 ms-2">Go Back</p></Link>

<div>
    <br />
</div>

        <p className="h4">Edit Profile</p>


        <form action="" id="frmregisterprofile" onSubmit={onSubmit}>
         
              <div className="row">

                <div className="form-group col-md-4">
                  <label htmlFor="name">Name <span style={{ color: 'red' }}><b>*</b></span></label>
                  <input type="text" className="form-control" name="name" id="name" value={name} onChange={onchange}></input>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="sex">Gender <span style={{ color: 'red' }}><b>*</b></span></label>
                  <select className="form-select form-select-sm form-control" name="sex" id="sex" value={sex} onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    <option value={"Male"}>Male</option>
                    <option value={"Female"}>Female</option>
                  </select>

                </div>

                <div className="form-group col-md-4">
                <label htmlFor="maritalstatus">Marital Status <span style={{color: 'red'}}><b>*</b></span></label>
                 <select className={ maritalstatus =='' ? "form-select form-select-sm bordererror": "form-select form-select-sm"} name="maritalstatus" value={maritalstatus} id="maritalstatus"  onChange={onchange} aria-label=".form-select-sm example">
                <option value="">Select</option>
                <option value={"Unmarried"}>Unmarried</option>
                <option value={"Widowed"}>Widowed</option>
                <option value={"Divorced"}>Divorced</option>
                <option value={"Awaiting Divorce"}>Awaiting Divorce</option>
                </select>

              </div>

              </div>
              <div className="row">

<div className="form-group col-md-4">
  <label htmlFor="colour">Colour</label>
  
  <select className= "form-select form-select-sm" name="colour" id="colour" value={colour} onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    <option value={"Very fair"}>Very fair</option>
                    <option value={"Fair"}>Fair</option>
                    <option value={"Medium"}>Medium</option>
                    <option value={"Dark Brown"}>Wheatish</option>
                    <option value={"Olive"}>Olive</option>
                    <option value={"Brown"}>Brown</option>
                    <option value={"Dark Brown"}>Dark Brown</option>
                    <option value={"Nil"}>Nil</option>
                  </select>
</div>
<div className="form-group col-md-4">
<label htmlFor="fatherName">Father Name</label>
<input type="text" className="form-control" name="fatherName" id="fatherName" value={fatherName} onChange={onchange}></input>

</div>

<div className="form-group col-md-4">
<label htmlFor="motherName">Mother Name</label>
<input type="text" className="form-control" name="motherName" id="motherName" value={motherName} onChange={onchange}></input>


</div>



</div>
<div className="row">

<div className="form-group col-md-4">
  <label htmlFor="height">Height</label>
  <input type="text" className="form-control" name="height" id="height" value={height} onChange={onchange}></input>
</div>
<div className="form-group col-md-4">
<label htmlFor="weight">Weight</label>
<input type="text" className="form-control" name="weight" id="weight" value={weight} onChange={onchange}></input>

</div>

<div className="form-group col-md-4">
<label htmlFor="motherName">Blood group</label>
<input type="text" className="form-control" name="bloodGroup" id="bloodGroup" value={bloodGroup} onChange={onchange}></input>


</div>



</div>

              
              <div className="row">
              
           
              <div className="form-group col-md-4">
                  <label htmlFor="qualification">Qualification <span style={{ color: 'red' }}><b>*</b></span></label>
                  <select className="form-select form-select-sm form-control" name="qualification" id="qualification" value={qualification} onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    {((qualifications != null && qualifications.length > 0) &&
                      qualifications.map((qualification) => (
                        <option key={qualification._id} value={qualification.qualification}>{qualification.qualification}</option>
                      ))
                    )}
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="job">Job</label>
                  <select className="form-select form-select-sm form-control" name="job" id="job" value={job} onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    {((jobs != null && jobs.length > 0) &&
                      jobs.map((job) => (
                        <option key={job._id} value={job.job}>{job.job}</option>
                      ))
                    )}
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="jobDescription">Job Description</label>
                  <input type="text" className="form-control" name="jobDescription" id="jobDescription" value={jobDescription} onChange={onchange} ></input>
                </div>
              </div>

              <div className="row">

<div className="form-group col-md-4">
    <label htmlFor="salary">Salary per month</label>
    <input type="text" className="form-control" name="salary" id="salary" value={salary} onChange={onchange} ></input>
  </div>
<div className="form-group col-md-4">
<label htmlFor="jobLocation">Job Location</label>
{/* <input type="text" className="form-control" name="jobLocation" id="jobLocation" value={jobLocation} onChange={onchange}></input> */}
<select className="form-control" name="jobLocation" id="jobLocation" value={jobLocation} onChange={onchange}>
<option value="">Select</option>
{(( jobLocations!= null && jobLocations.length > 0) &&

jobLocations.map((location) => (
<option key={location._id} value={location.location}>{location.location}</option>
))
)}  
</select>
</div>

<div className="form-group col-md-4">
<label htmlFor="foreignCountry">Foreign Country</label>
{/* <input type="text" className="form-control" name="foreignCountry" id="foreignCountry" value={foreignCountry} onChange={onchange}></input> */}
<select className="form-control" name="foreignCountry" id="foreignCountry" value={foreignCountry} onChange={onchange}>
<option value="">Select</option>

   {(( foreignCountrys!= null && foreignCountrys.length > 0) &&

     foreignCountrys.map((foreignCountry) => (
     <option key={foreignCountry._id} value={foreignCountry.foreignCountry}>{foreignCountry.foreignCountry}</option>
    ))
   )}          
    </select>       
</div>
</div>
              
              <div className="row">
                
                <div className="form-group col-md-4">
                  <label htmlFor="phoneNumber">Phone <span style={{ color: 'red' }}><b>*</b></span></label>
                  <input type="text" className="form-control" name="phoneNumber" id="phoneNumber" value={phoneNumber} onChange={onchange}></input>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="fatherOccupation">Father occupation</label>
                  <input type="text" className="form-control" name="fatherOccupation" id="fatherOccupation" value={fatherOccupation} onChange={onchange}></input>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="motherOccupation">Mother occupation</label>
                  <input type="text" className="form-control" name="motherOccupation" id="motherOccupation" value={motherOccupation} onChange={onchange} ></input>
                </div>

              </div>

              <div className="row">
                <div className="form-group col-md-4">
                  <label htmlFor="religion">Religion <span style={{ color: 'red' }}><b>*</b></span></label>
                  {/* <input type="text" className="form-control" name="religion" id="religion" value={religion} onChange={onchange}></input> */}
                  <select className={ religion =='' ? "form-select form-select-sm bordererror": "form-select form-select-sm"} name="religion" id="religion" value={religion} onChange={onchange}  aria-label=".form-select-sm example">
                <option value="">Select</option>
                {( (religions!=null && religions.length > 0) &&
                religions.map((religion) => (
                <option key={religion._id} value={religion.religion}>{religion.religion}</option>
                  ))
                )}
                </select>
                </div>
                <div className="form-group col-md-4">
                <label htmlFor="caste">Caste <span style={{color: 'red'}}><b>*</b></span></label>
                <select className={ caste =='' ? "form-select form-select-sm bordererror": "form-select form-select-sm"} name="caste" id="caste" value={caste} onChange={onchange}  aria-label=".form-select-sm example">
                <option value="">Select</option>
                {( (castes!=null && castes.length > 0) &&
                castes.map((caste) => (
                <option key={caste._id} value={caste.caste}>{caste.caste}</option>
                  ))
                )}
                </select>

              </div>
              <div className="form-group col-md-4">
                  <label htmlFor="subcaste">Sub Caste</label>
                  <input type="text" className="form-control" name="subcaste" id="subcaste" value={subcaste} onChange={onchange} ></input>
                  
                </div>

              </div>

              <div className="row">
                <div className="form-group col-md-4">
                  <label htmlFor="POB">Place of birth <span style={{ color: 'red' }}><b>*</b></span></label>
                  <input type="text" className="form-control" name="POB" id="POB" value={POB} onChange={onchange} ></input>
                </div>
                <div className="form-group col-md-4">
                  
                  <label className="datepickerLabel" htmlFor="DOB">Date of Birth(DD/MM/YYYY) <span style={{ color: 'red' }}><b>*</b></span></label>
                  {/* <input type="text" className="form-control" name="DOB" id="DOB" value={DOB} onChange={onchange}></input> */}
                  <DatePicker type="text" className="form-control" name="DOB" id="DOB" value={DOBBirth}
        selected={DOBBirth}
        onChange={date => setDOBDate(date)}
        dateFormat="dd/MM/yyyy"
        placeholderText="Select a date"
      />
               </div>
               <div className="form-group col-md-4">
                  <label htmlFor="birthTime">Time</label>
                  <input type="text" className="form-control" name="birthTime" id="birthTime" value={birthTime} onChange={onchange}></input>
                </div>
              </div>
              
              <div className="row">
                <div className="form-group col-md-4">
                  <label htmlFor="state">State</label>
                  <input type="text" className="form-control" id="state" name="state" value={state} onChange={onchange} ></input>
                </div>
               
                <div className="form-group col-md-4">
                  <label htmlFor="district">District <span style={{ color: 'red' }}><b>*</b></span></label>

                  <select className="form-select form-select-sm form-control" name="district" id="district" value={district} onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    {((districts != null && districts.length > 0) &&

                      districts.map((district) => (
                        <option key={district._id} value={district.distric}>{district.distric}</option>
                      ))
                    )}
                  </select>
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="settledLocation">Settled Location
                  </label>
                  {/* <input type="text" className="form-control" id="settledLocation" value={settledLocation} onChange={onchange} name="settledLocation"></input> */}
                  <select name="settledLocation" id="settledLocation" className="form-control" value={settledLocation} onChange={onchange}>
                  <option value="">Select</option>
                  {((settleLocations != null && settleLocations.length > 0) &&
                      settleLocations.map((location) => (
                    <option key={location._id} value={location.location}>{location.location}</option>
                  ))
                   )}

                 </select>
                </div>
                </div>
              

             

              <div className="row">
                <div className="form-group col-md-4">
                  <label htmlFor="star">Star <span style={{ color: 'red' }}><b>*</b></span></label>
                 
                  <select className="form-select form-select-sm form-control" name="star" id="star" value={star} onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    {((stars != null && stars.length > 0) &&

                      stars.map((star) => (
                        <option key={star._id} value={star.star}>{star.star}</option>
                      ))
                    )}
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="rasi">Rasi <span style={{ color: 'red' }}><b>*</b></span></label>
                  <select className="form-select form-select-sm form-control" name="rasi" id="rasi" value={rasi} onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    {((rasis != null && rasis.length > 0) &&

                      rasis.map((rasi) => (
                        <option key={rasi._id} value={rasi.rasi}>{rasi.rasi}</option>
                      ))
                    )}
                  </select>
                </div>
                <div className="form-group col-md-4">
                <label htmlFor="dhosam">Dhosam</label>
  
                   <select className= "form-select form-select-sm" name="dhosam" id="dhosam" value={dhosam} onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    <option value={"செவ்வாய் தோஷம்"}>செவ்வாய் தோஷம்</option>
                    <option value={"ராகு-கேது தோஷம்"}>ராகு-கேது தோஷம்</option>
                    <option value={"மாங்கல்ய தோஷம்"}>மாங்கல்ய தோஷம்</option>
                    <option value={"சூரிய தோஷம்"}>சூரிய தோஷம்</option>
                    <option value={"களத்திர தோஷம்"}>களத்திர தோஷம்</option>
                    
                  </select>
                  </div>
              </div>
              <div className="row">
              <div className="form-group col-md-3">
                  <label htmlFor="sistersMarried">Sister(s) Married
                  </label>
                  <input type="text" className="form-control" id="sistersMarried" onChange={onchange} name="sistersMarried"></input>
                </div>
              <div className="form-group col-md-3">
                <label htmlFor="sistersUnmarried">Sister(s) Unmarried
                </label>
                <input type="text"  className="form-control"  id="sistersUnmarried" value={sistersUnmarried} onChange={onchange} name="sistersUnmarried"></input>
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="brothersMarried">Brother(s) Married
                </label>
                <input type="text"  className="form-control"  id="brothersMarried" value={brothersMarried} onChange={onchange} name="brothersMarried"></input>
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="brothersUnmarried">Brother(s) Unmarried
                </label>
                <input type="text"  className="form-control"  id="brothersUnmarried" value={brothersUnmarried} onChange={onchange} name="brothersUnmarried"></input>
              </div>
              </div>
              <div className="row">
                <div className="form-group col-12">
                  <label htmlFor="address1">Address 1 <span style={{ color: 'red' }}><b>*</b></span></label>
                  <input type="text" className="form-control" name="address1" id="address1" value={address1} onChange={onchange}></input>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12">
                  <label htmlFor="address2">Address 2 <span style={{ color: 'red' }}><b>*</b></span></label>
                  <input type="text" className="form-control" name="address2" id="address2" value={address2} onChange={onchange}></input>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-12">
                  <label htmlFor="selfDescription">Self Description</label>
                  <textarea type="text" className="form-control" name="selfDescription" id="selfDescription" value={selfDescription} onChange={onchange}></textarea>
                </div>
              </div>
              <div className="row">
            <div className="form-group col-12">
                <label htmlFor="notes">Notes</label>
                <textarea type="text"  className="form-control" name="notes"  id="notes" value={notes} onChange={onchange}></textarea>
              </div>
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="status">Status <span style={{color: 'red'}}><b>*</b></span></label>
                 <select className={ status =='' ? "form-select form-select-sm bordererror": "form-select form-select-sm"} name="status" value={status} id="status"  onChange={onchange} aria-label=".form-select-sm example">
                <option value="">Select</option>
                <option value={"New"}>New</option>
                <option value={"Marriage fixed - Payment Incomplete"}>Marriage fixed - Payment Incomplete</option>
                <option value={"Marriage fixed - Payment Complete"}>Marriage fixed - Payment Complete</option>
                <option value={"Marriage Complete"}>Marriage Complete</option>
                </select>

              </div>
              
              <br />

              <div className="row" >
            <div className="button-container">
              <button className="primarybutton" type="submit">Save Changes</button>
              <button onClick={() => onCancelClick()} className="secondarybutton">Cancel</button>
            </div>

            

            <div className="col-3"></div>
             </div>

             <br /><br /><br />
             <p className="h4">Uploaded Images</p>

             <div className="row p-2"></div>
             <div className="row mb-5">
            {

              Images.map((image) => (
                <div key={image} className="col-md-4">
                  <div className="row mb-2">
                    <img src={image} alt="..."></img>
                  </div>
                  <div className="row mb-2">
                    <div className="col-6 d-flex justify-content-center"><button type="button" onClick={() => onProfileImageDelete(image)} className="secondarybutton">Delete Image</button></div>
                    <div className="col-6 d-flex justify-content-center"><button type="button" onClick={() => onsetProfile(image)} className="secondarybutton">Set as profile Image</button></div>
                  </div>
                </div>
              ))
            }

             </div>
           

             <div className="row p-2"></div>

<p className="h4">Upload profile photo</p>

<div className="row p-2">
  <div className="col-md-4 ">

    <div className="mb-3">
      <label htmlFor="formFile" className="form-label">Choose your photo</label>
      <input className="form-control" type="file" onChange={handleChange} id="formFile"></input>
      <span style={{color:"red"}}>* image size should be less than 300kb</span>
    </div>
  </div>
</div>
<div className="row  p-2">
  <div className="col-md-4"> <img src={fileurl} /></div>
</div>



<div className="row p-2">
  <div className="col-md-4">
    <button onClick={() => uploadImage()} className="primarybutton" >Save Image</button>
    {/* <button onClick={() => onCancelClick()} className="btn btn-danger">Cancel</button> */}
  </div>
</div>

        </form>
     
                </div>
             
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {(hasError == true ? (<p className="errorText">Name should be profile name</p>) : null)}

          <div className="form-group">
            <input className="form-control" onKeyUpCapture={onDeleteNamechange} onChange={onchange} name="deleteName" id="deleteName" type="text" placeholder="Enter the name" />
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onDeleteClick}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}
