import { useState,useEffect} from "react"
import { useSelector,useDispatch } from "react-redux"
import { getOfficerById,resetGetOfficerById} from "../../Features/Slices/officerSlice"
import { useSearchParams } from "react-router-dom"




export function EditOfficer(){
const dispatch=useDispatch()
const [searchParams] = useSearchParams();

    const officerId = searchParams.get('id');
 const [formData,setFormData]=useState({
    name:'',
    branch:'',
    age:'',
    gender:'',
    nationality:''
})    
const{name,branch,age,gender,nationality}=formData
const onchange=(e)=>{
    setFormData((prevState)=>({
        ...prevState,
        [e.target.name]:e.target.value
    }))};
    const {getOfficerById,resetGetOfficerById,isGetOfficerByIDSuccess,isGetOfficerByIDLoading}=
    useSelector((state)=>state.officer)
      useEffect(() => {
        if (isGetOfficerByIDLoading==false && isGetOfficerByIDSuccess==false) {
          dispatch(getOfficerById(officerId));
        }})



return(<>

          <form onSubmit={onsubmit}>
  <div className="row">
    <div className="form-group col-6">
        <label  htmlFor="name">Name</label>
        <input className="form-control" type="text"  name="name" id="id" onChange={onchange}/>
    </div>
    <div className="form-group col-6" >
        <label htmlFor="branch">Branch</label>
        <select className="form-control" name="branch" id="branch" onChange={onchange}>
            <option value="select">select</option>
            <option value="BSF">BSF</option>
            <option value="CRPF">CRPF</option>
            <option value="Coast Guard">Coast Guard</option>
            <option value="Space Force">Space Force</option>
            <option value="National Guard">National Guard</option>
        </select>
    </div>
  </div>
  <div className="row">
    <div className="form-group col-6">
        <label htmlFor="age">Age</label><br />
        <select className="form-control" name="age" id="age" onChange={onchange}>
            <option value="select">select</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
        </select>
    </div>

    <div className="form-group col-6">
        <label htmlFor="gender">Gender</label>
        <select  className="form-control" name="gender" id="gender" onChange={onchange}>
            <option value="select">select</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
        </select>
    </div>

    <div className="form-group col-12">
       <label htmlFor="nationality">Nationality</label>
     <input type="text"  className="form-control" name="nationality" id="nationality" onChange={onchange}/>
    </div>
  </div>
  
  

   <button type="sumbit" className="btn btn-dark">Update</button>
</form>



 
</>)}
