

const getUserData = () => {
   
    return JSON.parse(sessionStorage.getItem("user"))
  };

  const sessionDuration = 20 * 60 * 1000; // 30 minutes session duration
  const expirationTime = Date.now() + sessionDuration; // Expiry time (current time + session duration)

  const sessionData ={
    getUserData,
    expiresAt: expirationTime,
}

  // Store the session data in sessionStorage
  sessionStorage.setItem("session", JSON.stringify(sessionData));

export default sessionData 