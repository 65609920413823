import { Carousal } from "../Common/Carousal"
import "../../scss/publicProfile.scss"
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "../../scss/profileList.scss"
import { toast } from "react-toastify"
import { Link } from "react-router-dom";
import {
  registerProfile, resetRegisterProfile, resetRegisterMessages, getAllStars,
  getAllRasis, getAllDistricts, getAllJobs, getAllQualifications, getAllReligions,
  getAllCastes,getAllForeignCountries,getAllJobLocations,getAllSettleLocations
} from "../../Features/Slices/profSlice"
import { ValidateFields } from "../../Validation/Common/fieldValidation"

var RegisterProfileValidation = require('../../Validation/Config/RegisterProfile.json')




export function RegisterProfile() {

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const brokerIdParam = searchParams.get('id')
  const [DOBBirth, setDOBDate] = useState(null);
  let isImageCanUpload = false
  //dispatch(reset())
  const [formData, setFormData] = useState({
    name: '',
    maritalstatus: '',
    qualification: '',
    DOB: '',
    POB: '',
    birthTime: '',
    phoneNumber: '',
    job: '',
    salary: '',
    fatherOccupation: '',
    motherOccupation: '',
    sex: '',
    religion: '',
    caste: '',
    subcaste: '',
    district: '',
    region: '',
    state: '',
    address1: '',
    address2: '',
    star: '',
    rasi: '',
    sistersMarried: '',
    sistersUnmarried: '',
    brothersMarried: '',
    brothersUnmarried: '',
    notes: '',
    status: 'New',
    fatherName: '',
    motherName: '',
    colour: '',
    height: '',
    weight: '',
    bloodGroup: '',
    jobDescription: '',
    jobLocation: '',
    foreignCountry: '',
    settledLocation: '',
    dhosam: '',
    selfDescription: '',
    brokerId: brokerIdParam
  })

  const dateOfBirthOnchange = (date) => {
    setDOBDate(date)
    if (date == "" || date == null)
      document.getElementById("DOB").style.borderColor = "red";
    else
      document.getElementById("DOB").style.borderColor = "";
  }

  const profilelistUrl ='/ProfileList'

  const { name, maritalstatus, qualification, DOB, POB, birthTime, phoneNumber, job, salary,
    fatherOccupation, motherOccupation, sex, religion, caste, subcaste, district, region, state, address1,
    address2, star, rasi, sistersMarried, sistersUnmarried, brothersMarried, brothersUnmarried, notes, status, brokerId,fatherName, motherName, colour, height, weight, bloodGroup, jobDescription, jobLocation,foreignCountry, 
    settledLocation, dhosam, selfDescription } = formData

  const onchange = (e) => {

    //alert(e.target.name)

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const { broker, isError, isSuccess, isLoading, message, isStarListSuccess,
    RegisterProfilemessage, isRegisterProfileSuccess, isRegisterProfileError,
    registerProfilemessage, registerProfileId,
    profileDetails, isStarListLoading, stars, isRasiListLoading, isRasiListSuccess, rasis
    , isDistrictListLoading, isDistrictListSuccess, districts, qualifications,
    isQualificationLoading, isQualificationSuccess,
    jobs, isJobListLoading, isJobListSuccess, religions, isReligionError,
    isReligionSuccess, isReligionLoading, castes, iscasteError, iscasteSuccess, iscasteLoading,
    isForeignListSuccess,isForeignListLoading,foreignCountrys,isJobLocationSuccess,isJobLocationLoading,jobLocations,
    isSettleLocationSuccess,isSettleLocationLoading,settleLocations } =
    useSelector(
      (state) => state.prof
    )

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    if (isSuccess) {
      navigate('/BrokerList', { replace: true });
    }
    if (!isDistrictListLoading && !isDistrictListSuccess) {
      dispatch(getAllDistricts())
      console.log(districts)
    }

    if(!isForeignListLoading && !isForeignListSuccess){
      dispatch(getAllForeignCountries())
      console.log(foreignCountrys )
    }
    if(!isJobLocationLoading && !isJobLocationSuccess){
      dispatch(getAllJobLocations())
    }
    if(!isSettleLocationLoading && !isSettleLocationSuccess){
      dispatch(getAllSettleLocations())
    }
    if (!isStarListLoading && !isStarListSuccess) {
      dispatch(getAllStars())
    }
    if (!isRasiListLoading && !isRasiListSuccess) {
      dispatch(getAllRasis())
    }

    if (!isJobListLoading && !isJobListSuccess) {
      dispatch(getAllJobs())
    }

    if (!isQualificationLoading && !isQualificationSuccess) {
      dispatch(getAllQualifications())
    }

    if (!isReligionLoading && !isReligionSuccess) {
      dispatch(getAllReligions())
    }

    if (!iscasteLoading && !iscasteSuccess) {
      dispatch(getAllCastes())
    }
    if (DOBBirth == "" || DOBBirth == null)
      document.getElementById("DOB").style.borderColor = "red";
    else
      document.getElementById("DOB").style.borderColor = "";

  }, [broker, isError, isSuccess, isLoading, message, navigate], dispatch)

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    debugger
    if (isRegisterProfileSuccess) {
      toast.success("Profile added successfully")
      dispatch(resetRegisterMessages())
      navigate('/AddProfileImage?id=' + registerProfileId, { replace: true });
    }
    else if (isRegisterProfileError == true) {
      toast.error(registerProfilemessage)
      dispatch(resetRegisterMessages())
    }
  }, [isRegisterProfileSuccess, isRegisterProfileError]);




  const onSubmit = (e) => {
    e.preventDefault();

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.name
    }))


    let hasRequiredfieldValidation = false
    let hasOtherfieldValidation = false

    const brokerReqFields = {
      name, sex, job, phoneNumber, religion, caste, POB, DOBBirth, district, star, rasi,
      address1, address2
    }

    for (const [key, value] of Object.entries(brokerReqFields)) {

      if (value == "") {
        hasRequiredfieldValidation = true;
        return toast.error('Please fill all (*)required field')
      }
    }

    const profileData = {
      name, maritalstatus, qualification, DOBBirth, POB, birthTime, phoneNumber, job, salary,
      fatherOccupation, motherOccupation, sex, religion, caste, subcaste, district, state,
      address1, address2, star, rasi, brokerId, sistersMarried, sistersUnmarried, brothersMarried,
       brothersUnmarried, notes, status,fatherName, motherName, colour, height, weight, bloodGroup, jobDescription, jobLocation,
       foreignCountry, settledLocation, dhosam, selfDescription
    }

    if (hasRequiredfieldValidation == false) {
      for (const [key, value] of Object.entries(profileData)) {
        let arrValidation = RegisterProfileValidation.filter(validateprofile => validateprofile.fieldName === key)
        for (const currentObject of arrValidation) {
          let message = ValidateFields(currentObject, value);
          if (message != '') {
            hasOtherfieldValidation = true
            toast.error(message)
            return
          }
        }
      }
    }


    if (!hasRequiredfieldValidation && !hasOtherfieldValidation) {
      dispatch(registerProfile(profileData))
      setIsFormSubmitted(true);

      // if(isRegisterProfileSuccess == true)
      // {
      //   toast.success("Profile added successfully")
      //   navigate('/AddProfileImage?id='+ registerProfileId, { replace: true });
      // }
      // else if(isRegisterProfileError == true)
      // {
      //   toast.success(registerProfilemessage)
      // }

    }


  }


  const onCancelClick = () => {
    if (JSON.parse(sessionStorage["user"]).role == "Broker")
      navigate('/ProfileList?', { replace: true });
    else
      navigate('/BrokerList', { replace: true });
  }

  const onResetClick = () => {
    var registerProfileelement = document.getElementById("frmregisterprofile");
    registerProfileelement.reset()
    dispatch(resetRegisterProfile())
  }






  return (
    <>
      <div>

              <Link to={profilelistUrl} className="dropdown-item d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#1aa179" className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
        </svg><p className="h6 mb-0 ms-2">Go Back</p></Link>
        
        <div>
            <br />
        </div>
        

        <p className="h4">Add Profile</p>



        <form action="" id="frmregisterprofile" onSubmit={onSubmit}>
          <div id="dvRegisterProfile" className="row">
            <div className="col-md-12">

              <div className="row">

                <div className="form-group col-md-4">
                  <label htmlFor="name">Name <span style={{ color: 'red' }}><b>*</b></span></label>
                  <input type="text" className={name == '' ? "form-control bordererror" : "form-control"} name="name" id="name" onChange={onchange}></input>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="sex">Gender <span style={{ color: 'red' }}><b>*</b></span></label>
                  <select className={sex == '' ? "form-select form-select-sm bordererror" : "form-select form-select-sm"} name="sex" id="sex" onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    <option value={"Male"}>Male</option>
                    <option value={"Female"}>Female</option>
                  </select>

                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="maritalstatus">Marital Status <span style={{ color: 'red' }}><b>*</b></span></label>
                  <select className={maritalstatus == '' ? "form-select form-select-sm bordererror" : "form-select form-select-sm"} name="maritalstatus" id="maritalstatus" onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    <option value={"Unmarried"}>Unmarried</option>
                    <option value={"Widowed"}>Widowed</option>
                    <option value={"Divorced"}>Divorced</option>
                    <option value={"Awaiting Divorce"}>Awaiting Divorce</option>
                  </select>

                </div>



              </div>

              <div className="row">

<div className="form-group col-md-4">
  <label htmlFor="colour">Colour</label>
  
  <select className= "form-select form-select-sm" name="colour" id="colour" onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    <option value={"Very fair"}>Very fair</option>
                    <option value={"Fair"}>Fair</option>
                    <option value={"Medium"}>Medium</option>
                    <option value={"Dark Brown"}>Wheatish</option>
                    <option value={"Olive"}>Olive</option>
                    <option value={"Brown"}>Brown</option>
                    <option value={"Dark Brown"}>Dark Brown</option>
                    <option value={"Nil"}>Nil</option>
                  </select>
</div>
<div className="form-group col-md-4">
<label htmlFor="fatherName">Father Name</label>
<input type="text" className="form-control" name="fatherName" id="fatherName" onChange={onchange}></input>

</div>

<div className="form-group col-md-4">
<label htmlFor="motherName">Mother Name</label>
<input type="text" className="form-control" name="motherName" id="motherName" onChange={onchange}></input>


</div>



</div>

<div className="row">

<div className="form-group col-md-4">
  <label htmlFor="height">Height</label>
  <input type="text" className="form-control" name="height" id="height" onChange={onchange}></input>
</div>
<div className="form-group col-md-4">
<label htmlFor="weight">Weight</label>
<input type="text" className="form-control" name="weight" id="weight" onChange={onchange}></input>

</div>

<div className="form-group col-md-4">
<label htmlFor="motherName">Blood group</label>
<input type="text" className="form-control" name="bloodGroup" id="bloodGroup" onChange={onchange}></input>


</div>



</div>


              <div className="row">

                <div className="form-group col-md-4">
                  <label htmlFor="qualification">Qualification <span style={{ color: 'red' }}><b>*</b></span></label>
                  <select className={qualification == '' ? "form-select form-select-sm bordererror" : "form-select form-select-sm"} name="qualification" id="qualification" onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    {((qualifications != null && qualifications.length > 0) &&
                      qualifications.map((qualification) => (
                        <option key={qualification._id} value={qualification.qualification}>{qualification.qualification}</option>
                      ))
                    )}
                  </select>
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="job">Job</label>
                  <select className={job == '' ? "form-select form-select-sm bordererror" : "form-select form-select-sm"} name="job" id="job" onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    {((jobs != null && jobs.length > 0) &&
                      jobs.map((job) => (
                        <option key={job._id} value={job.job}>{job.job}</option>
                      ))
                    )}
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="jobDescription">Job Description</label>
                  <input type="text" className="form-control" name="jobDescription" id="jobDescription" onChange={onchange} ></input>
                </div>

              </div>

              <div className="row">

              <div className="form-group col-md-4">
                  <label htmlFor="salary">Salary per month</label>
                  <input type="text" className="form-control" name="salary" id="salary" onChange={onchange} ></input>
                </div>
<div className="form-group col-md-4">
<label htmlFor="jobLocation">Job Location</label>
{/* <input type="text" className="form-control" name="jobLocation" id="jobLocation" value={jobLocation} onChange={onchange}></input> */}
<select className="form-control" name="jobLocation" id="jobLocation" value={jobLocation} onChange={onchange}>
<option value="">Select</option>
{(( jobLocations!= null && jobLocations.length > 0) &&

jobLocations.map((location) => (
<option key={location._id} value={location.location}>{location.location}</option>
))
)}  
</select>
</div>

<div className="form-group col-md-4">
<label htmlFor="foreignCountry">Foreign Country</label>
{/* <input type="text" className="form-control" name="foreignCountry" id="foreignCountry" value={foreignCountry} onChange={onchange}></input> */}
<select className="form-control" name="foreignCountry" id="foreignCountry" value={foreignCountry} onChange={onchange}>
<option value="">Select</option>

   {(( foreignCountrys!= null && foreignCountrys.length > 0) &&

     foreignCountrys.map((foreignCountry) => (
     <option key={foreignCountry._id} value={foreignCountry.foreignCountry}>{foreignCountry.foreignCountry}</option>
    ))
   )}          
    </select>  

</div>



</div>

              <div className="row">

                <div className="form-group col-md-4">
                  <label htmlFor="phoneNumber">Phone <span style={{ color: 'red' }}><b>*</b></span></label>
                  <input type="text" className={phoneNumber == '' ? "form-control bordererror" : "form-control"} name="phoneNumber" id="phoneNumber" onChange={onchange}></input>
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="fatherOccupation">Father occupation</label>
                  <input type="text" className="form-control" name="fatherOccupation" id="fatherOccupation" onChange={onchange}></input>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="motherOccupation">Mother occupation</label>
                  <input type="text" className="form-control" name="motherOccupation" id="motherOccupation" onChange={onchange} ></input>
                </div>


              </div>
              <div className="row">

                <div className="form-group col-md-4">
                  <label htmlFor="religion">Religion <span style={{ color: 'red' }}><b>*</b></span></label>
                  <select className={religion == '' ? "form-select form-select-sm bordererror" : "form-select form-select-sm"} name="religion" id="religion" onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    {((religions != null && religions.length > 0) &&
                      religions.map((religion) => (
                        <option key={religion._id} value={religion.religion}>{religion.religion}</option>
                      ))
                    )}
                  </select>

                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="caste">Caste <span style={{ color: 'red' }}><b>*</b></span></label>
                  <select className={caste == '' ? "form-select form-select-sm bordererror" : "form-select form-select-sm"} name="caste" id="caste" onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    {((castes != null && castes.length > 0) &&
                      castes.map((caste) => (
                        <option key={caste._id} value={caste.caste}>{caste.caste}</option>
                      ))
                    )}
                  </select>

                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="subcaste">Sub Caste</label>
                  <input type="text" className="form-control" name="subcaste" id="subcaste" onChange={onchange} ></input>
                </div>

              </div>

              <div className="row">

                <div className="form-group col-md-4">
                  <label htmlFor="POB">Place of birth <span style={{ color: 'red' }}><b>*</b></span></label>
                  <input type="text" className={POB == '' ? "form-control bordererror" : "form-control"} name="POB" id="POB" onChange={onchange} ></input>
                </div>
                <div className="form-group col-md-4">
                  <label className="datepickerLabel" htmlFor="DOB">Date of Birth(DD/MM/YYYY) <span style={{ color: 'red' }}><b>*</b></span></label>
                  {/* <input type="text"  className={ DOB =='' ? "form-control bordererror": "form-control"} name="DOB"  id="DOB" onChange={onchange}></input> */}
                  <DatePicker type="text" className={DOBBirth == '' ? "form-control bordererror" : "form-control"} id="DOB" value={DOBBirth}
                    selected={DOBBirth}
                    onChange={date => dateOfBirthOnchange(date)}
                    dateFormat="dd/MM/yyyy"

                    placeholderText="Select a date"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="birthTime">Time</label>
                  <input type="text" className="form-control" name="birthTime" id="birthTime" onChange={onchange}></input>
                </div>



              </div>

              <div className="row">

                <div className="form-group col-md-4">
                  <label htmlFor="state">State</label>
                  {/* <input type="text"  readOnly className="form-control"  id="state" name="state" value={"Tamil nadu"} ></input> */}
                  <select className="form-control" name="state" id="state" onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    <option value="Tamil nadu">Tamil nadu</option>
                  </select>
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="district">District <span style={{ color: 'red' }}><b>*</b></span></label>

                  <select className={district == '' ? "form-select form-select-sm bordererror" : "form-select form-select-sm"} name="district" id="district" onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    {((districts != null && districts.length > 0) &&

                      districts.map((district) => (
                        <option key={district._id} value={district.distric}>{district.distric}</option>
                      ))
                    )}
                  </select>


                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="settledLocation">Settled Location
                  </label>
                 {/* <input type="text" className="form-control" id="settledLocation" value={settledLocation} onChange={onchange} name="settledLocation"></input> */}
                 <select name="settledLocation" id="settledLocation" className="form-control" value={settledLocation} onChange={onchange}>
                  <option value="">Select</option>
                  {((settleLocations != null && settleLocations.length > 0) &&
                      settleLocations.map((location) => (
                    <option key={location._id} value={location.location}>{location.location}</option>
                  ))
                   )}
                 </select>
                </div>
                {/* <div className="form-group col-md-4">
                <label htmlFor="region">Region</label>
                <select className={ region =='' ? "form-select form-select-sm bordererror": "form-select form-select-sm"} name="region" id="region"  onChange={onchange} aria-label=".form-select-sm example">
                <option value="">Select</option>
                <option value={"Nagercoil"}>Nagercoil</option>
                <option value={"Marthandam"}>Marthandam</option>
                <option value={"Kanyakumari"}>Kanyakumari</option>
                <option value={"Thuckalay"}>Thuckalay</option>
                <option value={"Kaliyakkavilai"}>Kaliyakkavilai</option>
                </select>
              </div> */}
              </div>
              

              <div className="row">
                <div className="form-group col-md-4">
                  <label htmlFor="star">Star <span style={{ color: 'red' }}><b>*</b></span></label>
                  <select className={star == '' ? "form-select form-select-sm bordererror" : "form-select form-select-sm"} name="star" id="star" onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    {((stars != null && stars.length > 0) &&

                      stars.map((star) => (
                        <option key={star._id} value={star.star}>{star.star}</option>
                      ))
                    )}
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="rasi">Rasi <span style={{ color: 'red' }}><b>*</b></span></label>
                  <select className={rasi == '' ? "form-select form-select-sm bordererror" : "form-select form-select-sm"} name="rasi" id="rasi" onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    {((rasis != null && rasis.length > 0) &&

                      rasis.map((rasi) => (
                        <option key={rasi._id} value={rasi.rasi}>{rasi.rasi}</option>
                      ))
                    )}
                  </select>
                </div>
                <div className="form-group col-md-4">
                <label htmlFor="dhosam">Dhosam</label>
  
                   <select className= "form-select form-select-sm" name="dhosam" id="dhosam" onChange={onchange} aria-label=".form-select-sm example">
                    <option value="">Select</option>
                    <option value={"செவ்வாய் தோஷம்"}>செவ்வாய் தோஷம்</option>
                    <option value={"ராகு-கேது தோஷம்"}>ராகு-கேது தோஷம்</option>
                    <option value={"மாங்கல்ய தோஷம்"}>மாங்கல்ய தோஷம்</option>
                    <option value={"சூரிய தோஷம்"}>சூரிய தோஷம்</option>
                    <option value={"களத்திர தோஷம்"}>களத்திர தோஷம்</option>
                    
                  </select>
                  </div>
              </div>
              <div className="row">
              <div className="form-group col-md-3">
                  <label htmlFor="sistersMarried">Sister(s) Married
                  </label>
                  <input type="text" className="form-control" id="sistersMarried" onChange={onchange} name="sistersMarried"></input>
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="sistersUnmarried">Sister(s) Unmarried
                  </label>
                  <input type="text" className="form-control" id="sistersUnmarried" onChange={onchange} name="sistersUnmarried"></input>
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="brothersMarried">Brother(s) Married
                  </label>
                  <input type="text" className="form-control" id="brothersMarried" onChange={onchange} name="brothersMarried"></input>
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="brothersUnmarried">Brother(s) Unmarried
                  </label>
                  <input type="text" className="form-control" id="brothersUnmarried" onChange={onchange} name="brothersUnmarried"></input>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-12">
                  <label htmlFor="address1">Address 1 <span style={{ color: 'red' }}><b>*</b></span></label>
                  <input type="text" className={address1 == '' ? "form-control bordererror" : "form-control"} name="address1" id="address1" onChange={onchange}></input>
                </div>
              </div>


              <div className="row">
                <div className="form-group col-12">
                  <label htmlFor="address2">Address 2 <span style={{ color: 'red' }}><b>*</b></span></label>
                  <input type="text" className={address2 == '' ? "form-control bordererror" : "form-control"} name="address2" id="address2" onChange={onchange}></input>
                </div>
              </div>


              <div className="row">
                <div className="form-group col-12">
                  <label htmlFor="selfDescription">Self Description</label>
                  <textarea type="text" className="form-control" name="selfDescription" id="selfDescription" onChange={onchange}></textarea>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12">
                  <label htmlFor="notes">Notes</label>
                  <textarea type="text" className="form-control" name="notes" id="notes" onChange={onchange}></textarea>
                </div>
              </div>

            </div>


          </div>

          {isLoading && <div className="loading-spinner">Loading...</div>}

          <div className="row"></div>
          <div>
            <div className="button-container">
              <button className="primarybutton" type="submit">Register</button>
              <button onClick={() => onResetClick()} className="secondarybutton" >Reset</button>
              <button onClick={() => onCancelClick()} className="secondarybutton">Cancel</button>
            </div>
          </div>
        </form>
      </div>



    </>




  )



}

