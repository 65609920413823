const ValidateFields = function ValidateFields(FormValidation, value) {
    let errorMessage = ''
    if (FormValidation.validationType == 'required') {
        if (value.length > parseInt(FormValidation.value)) {
            errorMessage = FormValidation.message
        }
    }
    else if (FormValidation.validationType == 'fieldLength') {
        if (value.length > parseInt(FormValidation.value)) {
            errorMessage = FormValidation.message
        }
    }
    else if (FormValidation.validationType == 'specialChar') {
        //var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        var format = /[!@#$%^&*()_+\-=\[\]{};'"\\|<>?~`]| {4,}/;
        if (format.test(value)) {
            errorMessage = FormValidation.message
        }
    }
    else if (FormValidation.validationType == 'specialCharForNotes') {
        //var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        var format = /[!@#$%^&*()_+\-=\[\]{};'"\\|<>?~`]/;
        if (format.test(value)) {
            errorMessage = FormValidation.message
        }
    }
    return errorMessage
}

export {ValidateFields}
