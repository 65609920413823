import authAxious from '../Config/interceptor'
import axios from 'axios'
const API_URL_REGISTER_USER ='/users'
const API_URL_GET_USERREGISTER_OTP ='/users/getNewRegisterOTP'
const API_URL_GET_USERRESETPASSWORD_OTP ='/users/getResetPasswordOTP'
const API_URL_REGISTER_LOGIN ='/users/login'
const API_URL_RESET_PASSWORD ='/users/resetUserPassword'
const API_URL_GETUSER_MENU ='/users/getUserMenuDetailsById'


const registerUser = async(userData) =>{
    const response = await authAxious.post(API_URL_REGISTER_USER,userData)
    return response.data;
}

const getResetPasswordOTP = async(data) =>{
    const response = await authAxious.post(API_URL_GET_USERRESETPASSWORD_OTP,data)
    return response.data;
}

const getUserRegisterOTP = async(data) =>{
    const response = await authAxious.post(API_URL_GET_USERREGISTER_OTP,data)
    return response.data;
}

const loginUser = async(userData) =>{
    const response = await authAxious.post(API_URL_REGISTER_LOGIN,userData)
   
    sessionStorage["user"] =JSON.stringify(response.data.data)

    const sessionDuration = 20 * 60 * 1000; // 30 minutes session duration
    const newExpirationTime = Date.now() + sessionDuration; // Expiry time (current time + session duration)
    sessionStorage.setItem("timer", newExpirationTime);
   
    return response.data;
}

const getUserMenuDetailsById = async(data) =>{
    const response = await authAxious.post(API_URL_GETUSER_MENU)
    return response.data;
}

const resetUserPassword =  async(data) =>{
    const response = await authAxious.post(API_URL_RESET_PASSWORD,data)
    return response.data;
}

const authService ={
    registerUser,
    loginUser,
    getUserMenuDetailsById,
    getUserRegisterOTP,
    getResetPasswordOTP,
    resetUserPassword
}

export default authService 
