import authAxious from '../Config/interceptor'
const API_URL_MASTER_ALL_STARS ='/master/getAllStars'
const API_URL_MASTER_ALL_RASIS ='/master/getAllRasi'
const API_URL_MASTER_ALL_DISTRICTS_BySTATE ='/master/getAllDistrictsByState'
const API_URL_MASTER_ALL_JOBS ='/master/getAllJobs'
const API_URL_MASTER_ALL_QUALIFICATIONS ='/master/getAllQualifications'
const API_URL_MASTER_ALL_RELIGIONS ='/master/getAllReligions'
const API_URL_MASTER_ALL_CASTES = '/master/getAllCaste'
const API_URL_MASTER_ALL_FOREIGN_COUNTRIES ='/master/getForeignCountries'
const API_URL_MASTER_ALL_JOB_LOCATIONS ='/master/getotherLocation'
const API_URL_MASTER_ALL_SETTLE_LOCATIONS ='/master/getotherLocation'
const API_URL_MASTER_QUALIFICATIONS_BY_ID='/master/getQualificationById'
const API_URL_CREATE_QUALIFICATION='/master/createQualification'
const API_GET_JOB_BYID='/master/getjobById'
const API_URL_CREATE_JOB='/master/createjob'

const getAllStars = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_STARS)
    return response.data;
}
const getAllRasis = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_RASIS)
    return response.data;
}
const getAllDistricts = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_DISTRICTS_BySTATE)
    return response.data;
}

const getAllJobs = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_JOBS)
    return response.data;
}
const getAllQualifications = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_QUALIFICATIONS)
    return response.data;
}

const getAllReligions = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_RELIGIONS)
    return response.data;
}

const getAllCastes = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_CASTES)
    return response.data;
}

const getAllForeignCountries = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_FOREIGN_COUNTRIES)
    return response.data;
}

const getAllJobLocations = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_JOB_LOCATIONS)
    return response.data;
}
const getAllSettleLocations = async() =>{
    const response = await authAxious.get(API_URL_MASTER_ALL_SETTLE_LOCATIONS)
    return response.data;
}



const getjobById = async(id) =>{
    const response = await authAxious.get(API_GET_JOB_BYID,{id})
    return response.id;
}


const createjob = async(data) =>{
    const response = await authAxious.post(API_URL_CREATE_JOB,{data})
    return response.data;
}


const getQualificationById =async(id) =>{
    const response =await authAxious.get(API_URL_MASTER_QUALIFICATIONS_BY_ID,{id})
    return response.data;
}

const createQualification=async(data)=>{
    const response=await authAxious.post(API_URL_CREATE_QUALIFICATION,{data})
    return response.data;
}



const masterService ={
    getAllStars,
    getAllRasis,
    getAllDistricts,
    getAllJobs,
    getAllQualifications,
    getAllReligions,
    getAllCastes,
    getAllForeignCountries,
    getAllJobLocations,
    getAllSettleLocations,
    createQualification,
    getQualificationById,
    getjobById,
    createjob
}

export default masterService 
