import{createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import masterService  from '../Services/masterService'

const initialState ={
    masterDataList:[{"stars":[],"states":[]}],
    isMasterError:false,
    isMasterSuccess:false,
    isMasterLoading:false,
    isMastermessage:'',

    masterDataReligionList:[],
    isMasterReligionError:false,
    isMasterReligionSuccess:false,
    isMasterReligionLoading:false,

    isQualificationListError:false,
    isQualificationSuccess:false,
    isQualificationLoading:false,
    qualifications:[],

    isQualificationByIdloading:false,
    isQualificationByIdSuccess:false,
    isQualificationByIdError:false,
    isQualificationByIdMessage:false,
    qualification:{},

    isCreateQualificationLoading:false,
    isCreateQualificationSuccess:false,
    isCreateQualificationError:false,
    isCreateQualificationMessage:'',

    isJobListLoading:false,
    isJobListSuccess:false,
    isJobListError:false,
    jobs:[],

    isCreateJobLoading:false,
    isCreateJobSuccess:false,
    isCreateJobSuccess:false,
    CreateJobMessage:''




}

export const getAllStars = createAsyncThunk(
    'master/getAllStars',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllStars()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllRasis = createAsyncThunk(
    'master/getAllRasis',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllRasis()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllReligions = createAsyncThunk(
    'master/getAllReligions',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllReligions();
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllQualifications= createAsyncThunk(
    'master/getAllQualifications',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllQualifications()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)


export const getQualificationById=createAsyncThunk(
   'master/getQualificationById',
   async(id,thunkAPI) =>{
    try{
        return await masterService.getQualificationById(id)
    }
    catch(error){
        const message = (error.response && error.response.data &&
            error.response.data.message) || error.message || error.tostring()

            return thunkAPI.rejectWithValue(message)
    }
   }
)

export const createQualification=createAsyncThunk(
    'master/createQualification',
    async(data,thunkAPI)=>{
        try{
            return await masterService.createQualification(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()
    
                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getjobById= createAsyncThunk(
    'master/getjobById',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getjobById()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllJobs= createAsyncThunk(
    'master/getAllJobs',
    async(user,thunkAPI)=>{
        try{
             return await masterService.getAllJobs()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)
export const createjob=createAsyncThunk(
    'master/createjob',
    async(data,thunkAPI)=>{
        try{
            return await masterService.createjob(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()
    
                return thunkAPI.rejectWithValue(message)
        }
    }
)
export const masterSlice = createSlice({
    name:'master',
    initialState,
    reducers:{
        resetMaster:(state)=>{
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ''
            state.masterDataList = [{"stars":[],"states":[]}]
        }

    },
    extraReducers:(builder)=>{
        builder
        .addCase(getAllStars.pending,(state)=>{
            state.isLoading = true;
        })
        .addCase(getAllStars.fulfilled,(state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.masterDataList.stars = action.payload
        })
        .addCase(getAllStars.rejected,(state,action)=>{
            state.isLoading = false
            state.isError = true
            state.masterDataList.stars = []
            
        })
        .addCase(getAllReligions.pending,(state)=>{
            state.isMasterReligionLoading = true;
        })
        .addCase(getAllReligions.fulfilled,(state,action)=>{
            state.isMasterReligionLoading = false
            state.isMasterReligionSuccess = true
            state.masterDataReligionList = action.payload
        })
        .addCase(getAllReligions.rejected,(state,action)=>{
            state.isMasterReligionLoading = false
            state.isMasterReligionError = true
            state.masterDataReligionList = []
        })
        .addCase(getAllQualifications.pending,(state)=>{
            state.isQualificationLoading = true;
         })
        .addCase(getAllQualifications.fulfilled,(state,action)=>{
             state.isQualificationLoading = false
             state.isQualificationSuccess = true
             state.qualifications = action.payload
         })
        .addCase(getAllQualifications.rejected,(state,action)=>{
             state.isQualificationLoading = false
             state.isQualificationListError = true
             state.qualifications = []
         })

         .addCase(getQualificationById.pending,(state)=>{
            state.isQualificationByIdloading =true;
         })
         .addCase(getQualificationById.fulfilled,(state,action)=>{
            state.isQualificationByIdloading = false
            state.isQualificationByIdSuccess = true
            if(action.payload.isSuccess ==true){
                state.qualification =action.payload.qualification
            }
            else{
                state.isMastermessage =action.payload.message;
                state.qualification={}
            }
         })
         .addCase(getQualificationById.rejected,(state,action)=>{
            state.isQualificationByIdloading =false
            state.isQualificationByIdSuccess =false
            state.isQualificationByIdError =true
            state.isQualificationByIdMessage =action.payload
            state.qualification ={}
         })

         .addCase(createQualification.pending,(state)=>{
             state.isCreateQualificationLoading=true;
         })
         .addCase(createQualification.fulfilled,(state,action)=>{
            state.isCreateQualificationLoading=false
            state.isCreateQualificationLoading=true;
            state.isCreateQualificationMessage=action.payload.message;
         })
         .addCase(createQualification.rejected,(state,action)=>{
            state.isCreateQualificationLoading=false
            state.isCreateQualificationError=true
            state.isCreateQualificationMessage=action.payload;
         })
         .addCase(getAllJobs.pending,(state)=>{
            state.isJobListLoading=true
         })
         .addCase(getAllJobs.fulfilled,(state,action)=>{
            state.isJobListLoading=false
            state.isJobListSuccess=true
            state.jobs=action.payload
         })
         .addCase(getAllJobs.rejected,(state,action)=>{
            state.isJobListLoading=false
            state.isJobListSuccess=false
            state.isJobListError=true
            state.jobs=[]
         })
    }    
   
})

export const {resetMaster} = masterSlice.actions
export default masterSlice.reducer