import{createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import authService  from '../Services/authService'

const initialState ={
    user:'',
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',

    isOTPError:false,
    isOTPSuccess:false,
    isOTPLoading:false,
    messageOTP:'',

    isOTPResetError:false,
    isOTPResetSuccess:false,
    isOTPResetLoading:false,
    messageResetOTP:'',

    isSubmitResetError:false,
    isSubmitResetSuccess:false,
    isSubmitResetLoading:false,
    messageResetSubmit:'',


    isSuccessMessage:true,
    isMenuError:false,
    isMenuSuccess:false,
    isMenuLoading:false,
    menumessage:'',
    menuItems:[]
}

export const registeruser = createAsyncThunk(
    'auth/registeruser',
    async(user,thunkAPI)=>{
        try{
             return await authService.registerUser(user)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)



export const loginuser = createAsyncThunk(
    'auth/loginUser',
    async(user,thunkAPI)=>{
        try{
             return await authService.loginUser(user)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getRegisterUserOTP = createAsyncThunk(
    'auth/getRegisterUserOTP',
    async(data,thunkAPI)=>{
        try{
             return await authService.getUserRegisterOTP(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getResetPasswordOTP = createAsyncThunk(
    'auth/getResetPasswordOTP',
    async(data,thunkAPI)=>{
        try{
             return await authService.getResetPasswordOTP(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getUserMenuDetailsById = createAsyncThunk(
    'auth/getUserMenuDetailsById',
    async(data,thunkAPI)=>{
        try{
           
             return await authService.getUserMenuDetailsById()
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const resetUserPassword = createAsyncThunk(
    'auth/resetUserPassword',
    async(data,thunkAPI)=>{
        try{
           
             return await authService.resetUserPassword(data)
        }
        catch(error){
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.tostring()

                return thunkAPI.rejectWithValue(message)
        }
    }
)

export const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.isSuccessMessage = true
            state.message = ''
            state.isOTPError = false
            state.isOTPSuccess= false
            state.isOTPLoading = false
            state.messageOTP =''

            state.isOTPResetError = false
            state.isOTPResetSuccess= false
            state.isOTPResetLoading = false
            state.messageOTPReset =''

            state.isSubmitResetError = false
            state.isSubmitResetSuccess = false
            state.isSubmitResetLoading = false
            state.messageResetSubmit =''

           
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(registeruser.pending,(state)=>{
            state.isLoading = true;
        })
        .addCase(registeruser.fulfilled,(state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload.data
            state.message = action.payload.message
            state.isSuccessMessage = action.payload.isSuccess
        })
        .addCase(registeruser.rejected,(state,action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.isSuccessMessage = false
            state.user = null
        })
        .addCase(loginuser.pending,(state)=>{
            state.isLoading = true;
        })
        .addCase(loginuser.fulfilled,(state,action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload.data
           
        })
        .addCase(loginuser.rejected,(state,action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.user = null
        }).addCase(getUserMenuDetailsById.pending,(state)=>{
            state.isMenuLoading = true;
        })
        .addCase(getUserMenuDetailsById.fulfilled,(state,action)=>{
            state.isMenuLoading = false
            state.isMenuSuccess = true
            state.menuItems = action.payload
           
        })
        .addCase(getUserMenuDetailsById.rejected,(state,action)=>{
            state.isMenuLoading = false
            state.isMenuError = true
            state.menuMessage = action.payload
            state.menuItems = null
        }).addCase(getRegisterUserOTP.pending,(state)=>{
            state.isOTPLoading = true;
        })
        .addCase(getRegisterUserOTP.fulfilled,(state,action)=>{
            state.isOTPLoading = false
            state.isOTPSuccess = true
            state.messageOTP = action.payload.message
        }) //getResetPasswordOTP
        .addCase(getRegisterUserOTP.rejected,(state,action)=>{
            state.isOTPLoading = false
            state.isOTPError = true
            state.messageOTP = action.payload
        }).addCase(getResetPasswordOTP.pending,(state)=>{
            state.isOTPResetLoading = true;
        })
        .addCase(getResetPasswordOTP.fulfilled,(state,action)=>{
            state.isOTPResetLoading = false
            state.isOTPResetSuccess = true
            if(action.payload.isSuccess == false)
            {
                state.isOTPResetSuccess = false
                state.isOTPResetError = true
            }
            state.messageOTPReset = action.payload.message
        }) 
        .addCase(getResetPasswordOTP.rejected,(state,action)=>{
            state.isOTPResetLoading = false
            state.isOTPResetError = true
            state.messageOTPReset = action.payload
        }).
        addCase(resetUserPassword.pending,(state)=>{
            state.isSubmitResetLoading = true;
        })
        .addCase(resetUserPassword.fulfilled,(state,action)=>{
            state.isSubmitResetLoading = false
            state.isSubmitResetSuccess = true
            if(action.payload.isSuccess == false)
            {
                state.isSubmitResetSuccess = false
                state.isSubmitResetError = true
            }
            state.messageResetSubmit = action.payload.message
        }) 
        .addCase(resetUserPassword.rejected,(state,action)=>{
            state.isSubmitResetLoading = false
            state.isSubmitResetError = true
            state.messageResetSubmit = action.payload
        })
    }    
})

export const {reset} = authSlice.actions
export default authSlice.reducer